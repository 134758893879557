import { OrbitControls } from '@react-three/drei';
import { ReactThreeFiber, useThree } from '@react-three/fiber';
import React, { memo, useMemo, useEffect, FunctionComponent, useRef } from 'react';
import { WebGLRenderer } from 'three';
import { MIN_ZOOM_LIMIT, MAX_ZOOM_LIMIT, ORTHOGRAPHIC_ZOOM_TO_PERSPECTIVE_POSITION, useGetActiveSceneTrackingType } from '../../../utils';
import { ITrackingTypes, IVector3 } from '../r3f-components/component-data-structure';
import { useRefState } from '../r3f-components/hooks';

interface IParentProps {
	zoomLevel: number;
	minZoom: number;
	maxZoom: number;
	is3dMode: boolean;
	projectId: string;
	target: IVector3;
	enabled?: boolean;
	onZoom: (zoomFactor: number) => unknown;
	onEnd?: (orbitControls: any) => unknown;
	onPanStart: (gl: WebGLRenderer) => unknown;
	onPanEnd: (gl: WebGLRenderer) => unknown;
	onUpdate?: (orbitControls: any) => unknown; // eslint-disable-line @typescript-eslint/no-explicit-any
	onChange?: (orbitControls: any) => unknown; // eslint-disable-line @typescript-eslint/no-explicit-any
}

// TODO - REFACTOR - Disabling any for the OrbitControls isn't ideal, but drei is importing from three-stdlib and doesn't play nice with React

const OrbitControlsComp: FunctionComponent<IParentProps> = ({ zoomLevel, enabled = true, minZoom, maxZoom, is3dMode, projectId, onZoom, onPanEnd, onUpdate, onPanStart, onEnd, onChange, target }) => {
	const orbitControlsRef = useRef<any>(); // eslint-disable-line @typescript-eslint/no-explicit-any
	const [_p, setIsPanning, isPanningRef] = useRefState(false);
	const { camera, gl, scene, raycaster } = useThree();
    const isRaycasterDisabledRef = useRef(false);
    const timeoutRef = useRef<NodeJS.Timeout>();

	const activeTrackingType = useGetActiveSceneTrackingType();

	useEffect(() => {
		if (camera.type === 'OrthographicCamera') {
			camera.position.setX(0);
			camera.position.setY(0);
		}
	}, [camera.type]);

	useEffect(() => {
        const onWheel = (e: WheelEvent) => {
            if (!isRaycasterDisabledRef.current) {
                isRaycasterDisabledRef.current = true;
                raycaster.layers.disableAll();
            }

			if (!is3dMode) {
				onZoom(camera.zoom);
				localStorage.setItem(`${projectId}_zoom_level`, `${camera.zoom}`);
			} else {
				let oldZoom = Number(localStorage.getItem(`${projectId}_zoom_level`));
				if (e.deltaY > 0) {
					oldZoom--;
				} else oldZoom++;
				if (oldZoom > MAX_ZOOM_LIMIT) oldZoom = MAX_ZOOM_LIMIT;
				if (oldZoom < MIN_ZOOM_LIMIT) oldZoom = MIN_ZOOM_LIMIT;
				onZoom(oldZoom);
				localStorage.setItem(`${projectId}_zoom_level`, `${oldZoom}`);
			}
            
            clearTimeout(timeoutRef.current)
            timeoutRef.current = setTimeout(() => {
                isRaycasterDisabledRef.current = false;
                raycaster.layers.enableAll()
            }, 100)
		};

		const pointerDown = (e: PointerEvent) => {
			if (e.buttons !== 2) return;
			onPanStart(gl);
			setIsPanning(true);
		};

		const pointerUp = () => {
			if (!isPanningRef.current) return;
			onPanEnd(gl);
			setIsPanning(false);
		};

		gl.domElement.addEventListener('wheel', onWheel, {passive: true});
		gl.domElement.addEventListener('pointerdown', pointerDown);
		gl.domElement.addEventListener('pointerup', pointerUp);

		return () => {
			gl.domElement.removeEventListener('wheel', onWheel);
			gl.domElement.removeEventListener('pointerdown', pointerDown);
			if (!isPanningRef.current) gl.domElement.removeEventListener('pointerup', pointerUp);
		};
	}, [onZoom, onPanStart, onPanEnd, setIsPanning]);

	const { minPos, maxPos } = useMemo(() => {
		return {
			minPos: ORTHOGRAPHIC_ZOOM_TO_PERSPECTIVE_POSITION * (MIN_ZOOM_LIMIT / zoomLevel),
			maxPos: ORTHOGRAPHIC_ZOOM_TO_PERSPECTIVE_POSITION * (MAX_ZOOM_LIMIT / zoomLevel),
		};
	}, [zoomLevel]);

	return (
		<OrbitControls
			ref={orbitControlsRef}
			makeDefault={true}
			onUpdate={onUpdate}
			onChange={onChange}
			minZoom={minZoom}
			maxZoom={maxZoom}
			enabled={enabled}
			enableZoom={activeTrackingType === ITrackingTypes.content360 ? false : true}
			minDistance={activeTrackingType === ITrackingTypes.content360 ? -10 : minPos}
			maxDistance={15}
			rotation={[0, 0, 45]}
			enableRotate={is3dMode}
			onEnd={() => onEnd?.(orbitControlsRef.current)}
			args={[camera, gl.domElement]}
			enableDamping={false}
			target={target as ReactThreeFiber.Vector3}
		/>
	);
};

export default memo(OrbitControlsComp);
