import React, { useMemo } from "react";
import { IComponentType, IComponentUnion, IFaceLandmarkGroup, ISceneComp, IScreenContent, ITrackingTypes } from "../component-data-structure";

const getSceneScreenContentId = (sceneId: string, componentsById: {[id: string]: IComponentUnion}): string => {
	const activeSceneChildren = (componentsById[sceneId] as ISceneComp)?.children || [];
	return activeSceneChildren.filter(id => componentsById[id].type === IComponentType.ScreenContent)[0]
}

export const getFaceLandmarkIdsBySceneId = (sceneId: string, componentsById: {[id: string]: IComponentUnion}): string[] => {
	const activeSceneChildren = (componentsById[sceneId] as ISceneComp)?.children || [];
    return activeSceneChildren.filter(id => componentsById[id].type === IComponentType.FaceLandmarkGroup);
}

export const useGetScreenRelativeComponentIds = (sceneId: string, componentsById: {[id: string]: IComponentUnion} | null): string[] => {
    return useMemo(() => {
        if (!componentsById) return [];
        const sceneContentId = getSceneScreenContentId(sceneId, componentsById)
        const sceneContent = componentsById[sceneContentId] as IScreenContent;

        return sceneContent.children.reduce((screenContentIds, anchorId) => {
            const child = componentsById[anchorId];
            if (!child) return screenContentIds;
            if (child.type === IComponentType.ScreenAnchorGroup) {
                screenContentIds.push(...child.children);
            }
            return screenContentIds;
        }, [] as string[])
    }, [componentsById, sceneId])
}

export const useGetFaceTrackedComponentIds = (sceneId: string, componentsById: {[id: string]: IComponentUnion} | null): string[] => {
    return useMemo(() => {
        return getFaceTrackedComponentIds(sceneId, componentsById);
    }, [componentsById, sceneId])
}

export const getFaceTrackedComponentIds = (sceneId: string, componentsById: { [id: string]: IComponentUnion } | null): string[] => {
    if (!componentsById) return [];
    if ((componentsById[sceneId] as ISceneComp).trackingType !== ITrackingTypes.face) return [];
    const faceLandmarkIds = getFaceLandmarkIdsBySceneId(sceneId, componentsById);
    return faceLandmarkIds.reduce((acc, landmarkId) => {
        const landmarkGroup = (componentsById[landmarkId] as IFaceLandmarkGroup);
        return [...acc, ...landmarkGroup.children];
    }, [] as string[]);
}