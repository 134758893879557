import { MathUtils } from 'three';
import { IScreenRelativeDevice, IScreenRelativeDevices } from '../../typings';
import { IMenuItem } from '../components/dom/menus/MenuList/MenuList';
import { IUploadFileTypes } from '../components/dom/upload/specs';
import {
	ITuple3,
	IFontTypes,
	IUnitTypes,
	IProjectColor,
	ITuple4,
	ITextAlignment,
	IFaceLandmark,
	IScreenAnchorPositionType,
	IEmitterCategory,
	IVector4,
} from '../components/r3f/r3f-components/component-data-structure';

// COLORS
export const COLORS = {
	midnight: '#073158',
	white: '#FFFFFF',
	lagoon: '#95C5FD',
	zapparorange: '#EF5332',
	zapparred: '#CE3923',
	mango: '#FEB34B',
	seabreeze: '#B7D5FA',
	breeze: '#E6F2FF',
	aether: '#BFCCFB',
	nebula: '#5276F7',
	star: '#F6F6F9',
	standbybluegray: '#B2C4D7',
};

export const MISSING_ASSET_TEXT = 'Missing asset'

export const CHROMA_KEY_DEFAULT_COLOR = null;
export const CHROMA_KEY_DEFAULT_SIMILARITY = 0.05;
export const CHROMA_KEY_DEFAULT_SMOOTHNESS = 0.08;
export const CHROMA_KEY_DEFAULT_SPILL = 0.02;

export const ICON_INACTIVE_COLOR = '#B4BBC3';

export const MAX_MENU_HEIGHT = '308px';
export const HEAD_MESH_DIMENSIONS: ITuple3 = [1.3, 1.85, 1.45];
export const SCREEN_RELATIVE_CANVAS_SIZE_FACTOR = 0.25;
export const SNAPSHOT_BUTTON_SCALE_X = 0.168;
export const SNAPSHOT_BUTTON_SCALE_Y = 0.166;
export const AR_CANVAS_SIZE_FACTOR = 0.5;
export const SR_AR_RATIO = SCREEN_RELATIVE_CANVAS_SIZE_FACTOR / AR_CANVAS_SIZE_FACTOR;
export const DEFAULT_TEXT_TITLE = 'Text';
export const DEFAULT_BUTTON_TITLE = 'Button';
export const DEFAULT_FACE_LANDMARK = IFaceLandmark.origin;
export const IMAGE_CANVAS_SIZE_FACTOR = 0.5;
export const VIDEO_CANVAS_SIZE_FACTOR = 1.5;
export const FONT_PT_TO_MM_HEIGHT = 0.352778;
export const HOTSPOT_SCALE: ITuple3 = [100, 100, 100];
export const INITIAL_ZOOM_LEVEL = 150;
export const MIN_ZOOM_LIMIT = INITIAL_ZOOM_LEVEL / 5;
export const MAX_ZOOM_LIMIT = INITIAL_ZOOM_LEVEL * 2;
export const ORTHOGRAPHIC_ZOOM_TO_PERSPECTIVE_POSITION = 6.145;
export const ROTATION_MARKER_HEIGHT = 32; //px
export const ROTATION_MARKER_WIDTH = 32; //px
export const SELECTION_MARKER_HEIGHT = 0.1;
export const SELECTION_MARKER_WIDTH = 0.1;
export const MINIMUM_SCALE_WIDTH_HEIGHT = 0.5;
export const CANVAS_PADDING_TOP = 30;
export const CANVAS_PADDING_RIGHT = 30;
export const CANVAS_PADDING_BOTTOM = 70;
export const CANVAS_PADDING_LEFT = 70;
export const CONTEXT_MENU_OFFSET_X = 20;
export const CONTEXT_MENU_OFFSET_Y = 20;
export const MENU_MARGIN = 5;
export const MENU_PADDING = 20;
export const ENTITY_PREVIEW_WIDTH = 250;
export const ENTITY_PREVIEW_HEIGHT = 170;
export const ENTITY_MENU_WIDTH = 60;
export const DEFAULT_SCENE_NAME = 'Scene';
export const NUM_OF_ENTITIES_DISPLAYED = 8;
export const SNAPSHOT_PX_HEIGHT = 170;
export const SNAPSHOT_TTL_MS = 1000 * 60 * 60 * 24 * 3; // 3 days in ms
export const MAINTENANCE_URL = 'https://s3-eu-west-1.amazonaws.com/wizcontent/maintenance/maintenance.json';
export const MAINTENANCE_INTERVAL = 1000 * 60 * 10; // every 10 minutes

export const MAX_MODEL_SIZE = 30000000; // 30 MB
export const MAX_IMAGE_SIZE = 10000000; // 10 MB
export const MAX_VIDEO_SIZE = 200000000; // 200 MB
export const MAX_AUDIO_SIZE = 50000000; // 50MB

export const ALLOWED_UPLOAD_IMAGE_TYPES = [IUploadFileTypes.image_png, IUploadFileTypes.image_jpeg, IUploadFileTypes.image_ext_fallback];

export const DEFAULT_SCREEN_RELATIVE_ANCHOR_POSITION = IScreenAnchorPositionType.center;
export const DEFAULT_SCREEN_RELATIVE_DEVICE = IScreenRelativeDevices.IPHONE12;
export const SCREEN_RELATIVE_DEVICES: Record<keyof typeof IScreenRelativeDevices, IScreenRelativeDevice> = {
	['IPHONE12']: { label: 'iPhone 12', height: 19.5, width: 9 },
	['IPHONE8']: { label: 'iPhone 8', height: 16, width: 9 },
	['PIXEL6a']: { label: 'Pixel 6a', height: 20, width: 9 },
	['GALAXYS7']: { label: 'Galaxy S7', height: 16, width: 9 },
	['IPAD']: { label: 'iPad', height: 3, width: 4 },
	['HORIZONTAL']: { label: 'Horizontal', height: 9, width: 16 },
	['CUSTOM']: { label: 'Custom', height: 800, width: 400 },
};

export const ACTION_ERROR_MESSAGES = {
	missingName: 'Name missing.',
	missingPhoneNumber: 'Phone number missing.',
	invalidPhoneNumber: 'Invalid phone number.',
	invalidEmail: 'Invalid email address.',
	invalidWebsite: 'Invalid website address.',
	missingSocial: 'Social profile url missing.',
	invalidSocial: 'Social provider not recognized. Select the custom link in the bottom right to link to other providers.',
};

export const ALLOWED_UPLOAD_VIDEO_TYPES = [IUploadFileTypes.video_all, IUploadFileTypes.video_ext_fallback];
export const ALLOWED_UPLOAD_AUDIO_TYPES = [IUploadFileTypes.audio_wav, IUploadFileTypes.audio_m4a, IUploadFileTypes.audio_mpeg, IUploadFileTypes.audio_ogg, IUploadFileTypes.audio_ext_fallback];
export const ALLOWED_UPLOAD_MODEL_TYPES = [IUploadFileTypes.model_glb_ext];
export const ALLOWED_360_BACKGROUND_UPLOAD_TYPES = [...ALLOWED_UPLOAD_VIDEO_TYPES, ...ALLOWED_UPLOAD_IMAGE_TYPES];


export interface IFontStyles {
	color: ITuple4;
	desc: string;
	fontSize: number;
	fontFamily: IFontTypes;
	fontRgba: ITuple4;
	scale: ITuple3;
	troikaSize?: number;
	id?: string;
}

export interface IText3dTextures {
	material: 'standard' | 'normal',
	fontRgba: ITuple4;
	text: string;
	fontSize: number;
	fontFamily: IFontTypes;
	textAlignment: ITextAlignment;
	depth: number;
	scale: ITuple3;
	id?: string;
}

export const TEXT_3D_TEXTURES: IText3dTextures[] = [
	{
		text: 'Add 3D heading',
		material: 'standard',
		fontRgba: [7, 49, 88, 1],
		fontSize: 0.60,
		fontFamily: IFontTypes.Roboto700,
		textAlignment: ITextAlignment.center,
		depth: 0.07,
		scale: [1.3, 1.3, 1.3]
	},
	{
		text: 'Add 3D subheading',
		material: 'standard',
		fontRgba: [7, 49, 88, 1],
		fontSize: 0.40,
		fontFamily: IFontTypes.Roboto700,
		textAlignment: ITextAlignment.center,
		depth: 0.06,
		scale: [1, 1, 1]
	},
	{
		text: 'Add 3D paragraph',
		material: 'standard',
		fontRgba: [7, 49, 88, 1],
		fontSize: 0.20,
		fontFamily: IFontTypes.Roboto400,
		textAlignment: ITextAlignment.center,
		depth: 0.04,
		scale: [0.66, 0.66, 0.66]
	},
	// {
	// 	text: 'ECCENTRIC',
	// 	material: 'normal',
	// 	fontRgba: [0, 0, 0, 1],
	// 	fontSize: 0.60,
	// 	fontFamily: IFontTypes.Creepster400,
	// 	textAlignment: ITextAlignment.center,
	// 	depth: 0.1,
	// 	scale: [1, 1, 1]
	// }
]

export const FONT_STYLES: IFontStyles[] = [
	{
		desc: 'Add heading',
		fontSize: 35,
		troikaSize: 0.21,
		fontRgba: [7, 49, 88, 1],
		scale: [0.34 * 2, 0.06 * 2, 0],
		fontFamily: IFontTypes.Roboto700,
		color: [0, 0, 0, 0],
	},
	{
		desc: 'Add subheading',
		fontSize: 20,
		troikaSize: 0.115,
		fontRgba: [7, 49, 88, 1],
		scale: [0.305 * 2, 0.05 * 2, 0],
		fontFamily: IFontTypes.Roboto700,
		color: [0, 0, 0, 0],
	},
	{
		desc: 'Add paragraph',
		fontSize: 20,
		troikaSize: 0.115,
		fontRgba: [7, 49, 88, 1],
		scale: [0.22 * 2, 0.03 * 2, 0],
		fontFamily: IFontTypes.Roboto400,
		color: [0, 0, 0, 0],
	},
];

export const METRIC_UNIT_MENU_OPTIONS: IMenuItem[] = [
	{ text: 'millimetres', val: IUnitTypes.mm, testId: 'millimetres' },
	{ text: 'centimetres', val: IUnitTypes.cm },
	// { text: 'metres', val: IUnitTypes.m },
	{ text: 'inches', val: IUnitTypes.inch },
	{ text: 'feet', val: IUnitTypes.ft },
	{ text: 'coordinates', val: IUnitTypes.coords },
];

export const BASIC_COLORS: IProjectColor[] = [
	{ id: MathUtils.generateUUID(), hex: '#57A6FF', rgba: [87, 166, 255, 100] },
	{ id: MathUtils.generateUUID(), hex: '#0CD185', rgba: [12, 209, 133, 100] },
	{ id: MathUtils.generateUUID(), hex: '#77D353', rgba: [119, 211, 83, 100] },
	{ id: MathUtils.generateUUID(), hex: '#FFBA5C', rgba: [255, 186, 92, 100] },
	{ id: MathUtils.generateUUID(), hex: '#B8977E', rgba: [184, 151, 126, 100] },
	{ id: MathUtils.generateUUID(), hex: '#FF9052', rgba: [255, 144, 82, 100] },
	{ id: MathUtils.generateUUID(), hex: '#F95F62', rgba: [249, 95, 98, 100] },
	{ id: MathUtils.generateUUID(), hex: '#F77FB3', rgba: [247, 127, 179, 100] },
	{ id: MathUtils.generateUUID(), hex: '#976DD0', rgba: [151, 109, 208, 100] },
	{ id: MathUtils.generateUUID(), hex: '#343F4B', rgba: [52, 63, 75, 100] },
	{ id: MathUtils.generateUUID(), hex: '#5A6978', rgba: [90, 105, 120, 100] },
	{ id: MathUtils.generateUUID(), hex: '#969FAA', rgba: [150, 159, 170, 100] },
	{ id: MathUtils.generateUUID(), hex: '#C6CBD0', rgba: [198, 203, 208, 100] },
	{ id: MathUtils.generateUUID(), hex: '#FFFFFF', rgba: [255, 255, 255, 100] },
];

// Height reference is the approximate height of a non-social button and we are scaling the social buttons relative to this, so they appear on the canvas with the same height and correct aspect ratio
export const BTN_HEIGHT_REFERENCE = 0.08;
export const BTN_SHARED_PROPS = {
	position: [0, 0, 0],
	rotation: [0, 0, 0],
	fontFamily: IFontTypes.Roboto400,
	fontSize: 0.11,
	fontRgba: [255, 255, 255, 0],
	color: [[255, 255, 255, 0]] as IVector4[],
	borderRadius: 0,
	borderWidth: 0,
	borderRgba: [150, 191, 239, 1],
	textAlignment: ITextAlignment.center,
};

export enum IEmitterTextureKey {
	bubble = 'bubble',
	heart = 'heart',
	rain = 'rain',
	raindrop = 'raindrop',
	snow = 'snow',
	sparkle = 'sparkle',
	star = 'star',
	smoke = 'smoke',
}

export interface IEmitterTexture {
	title: string;
	url: string;
	hidden?: boolean;
}

export const EMITTER_TEXTURE_DICT: Record<keyof typeof IEmitterTextureKey, IEmitterTexture> = {
	[IEmitterTextureKey.bubble]: { title: 'Bubble', url: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/particles/bubble-v1.png' },
	[IEmitterTextureKey.heart]: { title: 'Heart', url: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/particles/heart-v1.png' },
	[IEmitterTextureKey.rain]: { title: 'Rain', url: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/particles/rain-v1.png' },
	[IEmitterTextureKey.raindrop]: { title: 'Raindrop', url: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/particles/raindrop-v1.png' },
	[IEmitterTextureKey.snow]: { title: 'Snow', url: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/particles/snow-v1.png' },
	[IEmitterTextureKey.sparkle]: { title: 'Sparkle', url: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/particles/sparkle-v1.png' },
	[IEmitterTextureKey.star]: { title: 'Star', url: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/particles/star-v1.png' },
	[IEmitterTextureKey.smoke]: { title: 'Smoke', url: 'https://d1mfzu0xo6h6ih.cloudfront.net/designer/smoke-particle-v1.png', hidden: true },
};

export const EMITTER_CATEGORY_TEXTURE_DICT: Partial<Record<IEmitterCategory, IEmitterTextureKey>> = {
	['rain']: IEmitterTextureKey.rain,
	['snow']: IEmitterTextureKey.snow,
	['sparkles']: IEmitterTextureKey.sparkle,
	['fire']: IEmitterTextureKey.smoke,
};

export const EMITTER_CATEGORY_TITLE_DICT: Partial<Record<IEmitterCategory, string>> = {
	['rain']: 'Rain',
	['snow']: 'Snow',
	['confetti']: 'Confetti',
	['sparkles']: 'Sparkles',
	['fire']: 'Fire',
};
