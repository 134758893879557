import { isSafariWithAudioContextBug } from "./general";

export interface IPauseAudioInput {
	excludedIds?: string[];
	resetToStart?: boolean;
    stopBkgrAudio?: boolean;
}

export interface IPlayAudioInput {
    entityIds: string[];
    loop?: boolean;
}

interface ISetupAudioData {
    src: string,
    loop: boolean,
    id: string;
}

export type ISetupAudioInput = ISetupAudioData[];

type IPauseAudio = (a?: IPauseAudioInput) => void;
type ISetupAudio = (audioContext: AudioContext, audioStreamDestination: MediaStreamAudioDestinationNode, data: ISetupAudioInput) => void;
type IPlayAudio = (a: IPlayAudioInput & { experienceIsMuted: boolean;}) => void;

interface IAudioDict {
    [audioId: string]: HTMLAudioElement[]
}

class SceneAudioManager {
    private _audioDict: IAudioDict = {};
    public bkgrAudio: HTMLAudioElement;
    private _bkgrAudioHasFinished = false;

    constructor(bkgrAudio: HTMLAudioElement) {
        this.bkgrAudio = bkgrAudio;
        this.bkgrAudio.addEventListener('ended', () => this._bkgrAudioHasFinished = true);
    }

    setupAudio: ISetupAudio = (audioContext, audioStreamDestination, sceneAudio) => {
        //console.log('SETUP AUDIO');
        for (let i = 0; i < sceneAudio.length; i++) {
            const {src, id: audioId, loop} = sceneAudio[i];
            if (!src || !audioId) continue;
            //if (this._audioDict[audioId]) continue;
            const audioEl = new Audio(src);
            audioEl.crossOrigin = "anonymous";
            if (typeof loop !== 'undefined') audioEl.loop = loop;

            // muting audio el and pausing after play start to ensure safari multi-audiotrack permission 
            // is granted when playing multiple audio later
            audioEl.muted = true;
            try {
                audioEl.play().then(() => {
                    audioEl.pause();
                })
                if (!isSafariWithAudioContextBug()) {
                    addMediaElementToAudioStreamDestination(audioContext, audioStreamDestination, audioEl);
                }
                // Pause and mute audio again in case adding node to audio context results in immediate audio triggering
                audioEl.muted = true;
                audioEl.pause();
                audioEl.currentTime = 0;
            } catch (error) {
                console.warn(error)
            }

            if (!this._audioDict[audioId]) this._audioDict[audioId] = [];
            this._audioDict[audioId].push(audioEl);
        }
    }

    playAudio: IPlayAudio = ({entityIds, loop = false, experienceIsMuted = false}) => {
        let numberAudioPlaying = 0;
        for (let i = 0; i < entityIds.length; i++) {
            const audioId = entityIds[i];
            const audioElArray = this._audioDict[audioId];
            if (typeof audioElArray === 'undefined') continue;
            for (let j = 0; j < audioElArray.length; j++) {
                const audioEl = audioElArray[j];
                if (loop) audioEl.loop = true;
                audioEl.currentTime = 0;
                // unmuting audio after play start to ensure safari multi-audiotrack permission granted
                audioEl.play().then(() => audioEl.muted = experienceIsMuted)

                // audioEl.play();
                numberAudioPlaying++

                audioEl.addEventListener('ended', ()  => {
                    if (numberAudioPlaying === 1 && this.bkgrAudio.paused && !this._bkgrAudioHasFinished) {
                        this.bkgrAudio.play();
                    }
                    numberAudioPlaying--;
                });
            }

            
        }
    }

    pauseAudio: IPauseAudio = (args) => {
        if (args?.stopBkgrAudio) this.bkgrAudio.pause();
        const audioIds = Object.keys(this._audioDict);
        for (let i = 0; i < audioIds.length; i++) {
            const audioId = audioIds[i];
            const audioElArray = this._audioDict[audioId];
            if (typeof audioElArray === 'undefined') continue;
            for (let j = 0; j < audioElArray.length; j++) {
                const audioEl = audioElArray[j];
                if (Array.isArray(args?.excludedIds) && args?.excludedIds?.includes(audioId)) continue;
                audioEl.pause();
                if (args?.resetToStart) audioEl.currentTime = 0;
            }
        }
    }
    unmuteAllPlayingAudio = (): void => {
        for (const id in this._audioDict) {
            const audioElArray = this._audioDict[id];
            if (typeof audioElArray === 'undefined') continue;
            for (let i = 0; i < audioElArray.length; i++) {
                const audioEl = audioElArray[i];
                if (!audioEl.paused) audioEl.muted = false
            }
           
        }
    } 
    muteAllAudio = (): void => {
        for (const id in this._audioDict) {
            const audioElArray = this._audioDict[id];
            if (typeof audioElArray === 'undefined') continue;
            for (let i = 0; i < audioElArray.length; i++) {
                const audioEl = audioElArray[i];
                audioEl.muted = true;
            }
        }
    } 

    // getAllSceneAudioElements = (): HTMLAudioElement[] => {
    //     const audioElements = Object.values(this._audioDict).map(data => data[1]);
    //     audioElements.push(this.bkgrAudio);
    //     return audioElements;
    // }

    playBackgroundAudio = (muted: boolean): void => {
        if(this._bkgrAudioHasFinished || !this.bkgrAudio.paused) return;
        this.bkgrAudio.play().then(() => this.bkgrAudio.muted = muted)
    }

    get backgroundAudioHasFinised(): boolean {
        return this._bkgrAudioHasFinished
    }
}

const addMediaElementToAudioStreamDestination = (audioContext: AudioContext, audioStreamDestination: MediaStreamAudioDestinationNode, element: HTMLVideoElement | HTMLAudioElement): MediaStreamAudioDestinationNode => {
    const audioSourceNode = audioContext.createMediaElementSource(element);
    audioSourceNode.connect(audioStreamDestination);
    audioSourceNode.connect(audioContext.destination);
    return audioStreamDestination
}



export { SceneAudioManager, addMediaElementToAudioStreamDestination };