import { ThreeEvent } from '@react-three/fiber';
import * as Sentry from '@sentry/react';
import React, { FunctionComponent, ReactNode, useEffect, useMemo } from 'react';
import { ITuple3, ITuple4 } from '../../component-data-structure';
import BrokenIcon from './BrokenIcon/BrokenIcon';
import PlaceholderEntity from './PlaceholderEntity';

interface IParentProps {
	scale: ITuple3;
	rotation: ITuple3;
	position?: ITuple3;
	renderOrder: number;
	children?: ReactNode;
    forceError?: boolean;
	onError?: (error: Error, componentStack?: string, eventId?: string) => unknown;
	onPointerUp?: (e: ThreeEvent<PointerEvent>) => unknown;
	onPointerDown?: (e: ThreeEvent<PointerEvent>) => unknown;
}

const ErrorBoundary: FunctionComponent<IParentProps> = ({ forceError = false, scale, rotation, position = [0, 0, 0], renderOrder, children, onPointerUp, onPointerDown, onError }) => {
    
    useEffect(() => {
        if (!forceError) return;
        const err = new Error('error loading component')
        onError?.(err);

        Sentry.startSpan({ name: "entity-load-error" }, () => {
            Sentry.captureException(err)
        });

        
    }, [forceError, onError])
    
    const memo = useMemo(
		() => ({
			scale,
			rotation,
			position,
			fillRgba: [227, 227, 227, 1] as ITuple4,
			borderRgba: [181, 181, 181, 1] as ITuple4,
		}),
		[scale, rotation, position]
	);

    const errorPlaceholder = (
        <PlaceholderEntity
            rotation={memo.rotation}
            fillRgba={memo.fillRgba}
            scale={memo.scale}
            position={memo.position}
            renderOrder={renderOrder}
            onPointerDown={onPointerDown || undefined}
            onPointerUp={onPointerUp || undefined}
        >
            <BrokenIcon scale={memo.scale} renderOrder={renderOrder + 2} />
        </PlaceholderEntity>
    );

	return (
		<Sentry.ErrorBoundary
			fallback={errorPlaceholder}
			onError={(e, compStack, eventId) => {
				onError?.(e as Error, compStack || '', eventId);
			}}
		>
			{forceError ? errorPlaceholder : children}
		</Sentry.ErrorBoundary>
	);
};

export default ErrorBoundary;