import { ThreeEvent, useThree } from '@react-three/fiber';
import { useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { IDesignerState, ITransformControlsMode } from '../../../../typings';
import {
	onRemoveSelection,
	onAddEntityIdsToSelection,
	onSetPositioningIsActive,
	onSetRotationIsActive,
	IMultipleEntityProps_Cn_Doc,
	onSetMultipleComponentProps_Cn_Doc,
	onSetPositions,
	onSetTransformControlsMode,
	onSetMultipleEntitySelectBoundary,
	onSetActiveAnimationPreset,
} from '../../../store/actions';
import { hotkeyIsPressed, IHotkeyTypes, maths, useGetEntityTransientColors, useGetSiblings } from '../../../utils';
import { IButton, ICurveComponentUnion, IImage, ISpatialComponentUnion, IText, IText3d, ITuple3, ITuple3Dict, IVector3, IVector3Dict, IVideo } from '../r3f-components/component-data-structure';
import { getInitialEntityTransformValuesByIds } from '../r3f-components/utils/actions';
import { isAbstractComponent, isCurveComponent } from '../r3f-components/utils/general';
import { getEntityRenderOrder, isHighestPointerPriority } from './hocUtils';
const { vec3 } = maths;

export const useHocPointerDown = (args: {
	pointerStageRef: React.MutableRefObject<'down' | 'move' | 'up' | null>;
	initialPosRef: React.MutableRefObject<ITuple3 | null>;
	entity: ISpatialComponentUnion;
	setShowEntityPositionHotspot?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
	const { pointerStageRef, initialPosRef, entity, setShowEntityPositionHotspot } = args;
	const { id } = entity;
	const renderOrder = getEntityRenderOrder(entity);

	const { raycaster, scene } = useThree();
	const dispatch = useDispatch();
	const isScreenRelativeMode = useSelector((state: IDesignerState) => state.userReducer.isScreenRelativeMode);
	const transformControlsMode = useSelector((state: IDesignerState) => state.userReducer.transformControlsMode);
	const hotKeys = useSelector((state: IDesignerState) => state.userReducer.hotKeys);
	const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds || []);
	const rotationMarkerPressed = useSelector((state: IDesignerState) => state.userReducer.markerIndexPressed === 999);
	const is3dMode = useSelector((state: IDesignerState) => state.userReducer.is3dMode);
	const stopPropagation = useIsAllHotspotsCompetingWithSelectedEntityPositionHotspotInactive();
	const siblings = useGetSiblings();

	return (e: ThreeEvent<PointerEvent>) => {	
		pointerStageRef.current = 'down';

		if (rotationMarkerPressed || e.buttons === 2) return;
		const rayintersects = raycaster.intersectObjects(scene.children).filter(int => int.object.name !== 'emitterAreaLine');

		if (!isHighestPointerPriority(id, rayintersects, siblings, renderOrder, is3dMode, isScreenRelativeMode)) return;

		// Track pointer on canvas in case user drags over non-canvas (dom) elements
		(e.target as HTMLElement).setPointerCapture(e.pointerId);

		if (stopPropagation) e.stopPropagation();
		const groupSelectHotkeyIsPressedIn2dMode = hotkeyIsPressed(hotKeys, [IHotkeyTypes.groupSelect]) && (!is3dMode || isScreenRelativeMode);
		const entityIsCurrentlySelected = selectedEntityIds.includes(id);

		// In 2D mode, remove OTHER entities from selection ( if there are some group selected ), if user isn't pressing shift
		if (!groupSelectHotkeyIsPressedIn2dMode && !entityIsCurrentlySelected) {
			const otherSelectedEntityIds = selectedEntityIds.filter((selId) => selId !== id);
			dispatch(onRemoveSelection(otherSelectedEntityIds));
		}

		// If shift is pressed and user clicks entity, then the entity should be removed from selection
		// if (groupSelectHotkeyIsPressedIn2dMode && entityIsCurrentlySelected) {
		// 	dispatch(onRemoveSelection([id]));
		// }

		// SR mode doesn't allow group select - remove all selections
		if (!entityIsCurrentlySelected && isScreenRelativeMode) dispatch(onRemoveSelection());

		// Add self to selection
		if (!entityIsCurrentlySelected) dispatch(onAddEntityIdsToSelection([id]));

		// If a curved entity and transform mode isn't scale, set to curved controls if it's currently snapped to target
		if (isCurveComponent(entity) && transformControlsMode !== ITransformControlsMode.scale && !!entity.isSnappedToTarget) {
			dispatch(onSetTransformControlsMode(ITransformControlsMode.curve));
		}
		// If a curved entity and transform mode is curve, but this entity isn't snapped, reset to Translate
		if (transformControlsMode === ITransformControlsMode.curve && (!isCurveComponent(entity) || !entity.isSnappedToTarget)) {
			dispatch(onSetTransformControlsMode(ITransformControlsMode.translate));
		}

		if (!is3dMode || isScreenRelativeMode) dispatch(onSetPositioningIsActive(true));
		dispatch(onSetRotationIsActive(false));
		initialPosRef.current = [e.point.x, e.point.y, e.point.z];
		setShowEntityPositionHotspot?.(true);
		dispatch(onSetActiveAnimationPreset(false))
	};
};

export const useHocPointerMove = (args: {
	pointerStageRef: React.MutableRefObject<'down' | 'move' | 'up' | null>;
	previousPositionRef: React.MutableRefObject<ITuple3 | null>;
	shiftLockedRef: React.MutableRefObject<number | null>;
	pointerUpHasRunRef?: React.MutableRefObject<boolean | null>;
	initialPosRef: React.MutableRefObject<ITuple3 | null>;
	entity: ISpatialComponentUnion;
}) => {
	const { pointerStageRef, previousPositionRef, initialPosRef, entity, pointerUpHasRunRef, shiftLockedRef } = args;
	const isLocked = !!entity.isLocked;
	const dispatch = useDispatch();
	const is3dMode = useSelector((state: IDesignerState) => state.userReducer.is3dMode);
	const isScreenRelativeMode = useSelector((state: IDesignerState) => state.userReducer.isScreenRelativeMode);
	const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds || []);
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	const scaleHotspotIsEnabled = useSelector((state: IDesignerState) => state.userReducer.scaleHotspotIsEnabled);
	const selectedContentPositions = useGetSelectedContentPositions();
	const hotkeys = useSelector((state: IDesignerState) => state.userReducer.hotKeys);

	return (e: ThreeEvent<PointerEvent>) => {
		// console.log('pointer move 1')
		// console.log('pointer move > pointerStageRef.current: ', pointerStageRef.current);
		if (pointerStageRef.current !== 'down' && pointerStageRef.current !== 'move') return;
		pointerStageRef.current = 'move';
		if(pointerUpHasRunRef) pointerUpHasRunRef.current = false;
		if (is3dMode && !isScreenRelativeMode) return;
		if (scaleHotspotIsEnabled) return;

		// Return if entity is locked or entity not dragged
		if (e.buttons === 2 || !selectedEntityIds) return;
		if (selectedEntityIds.length === 1 && isLocked) return;
		if (selectedEntityIds.length > 1 && selectedEntityIds.filter((id) => (componentsById[id] as ISpatialComponentUnion).isLocked).length !== 0) return;
		// if (doubleClicked) setDoubleClicked(false);

		// Return if position has not changed (re-render and/or continuous firing)
		const { x, y, z } = e.point;
		const noMove = previousPositionRef.current && vec3.equal([x, y, z], previousPositionRef.current as ITuple3);
		if (noMove) return;
		previousPositionRef.current = [x, y, z];
		initialPosRef.current = initialPosRef.current || [x, y, z];

		// Calculate pointer offset on hotspot
		const pointerDiff = vec3.subtract([x, y, z], initialPosRef.current as ITuple3);
		if (hotkeys.includes('Shift') && shiftLockedRef.current == null) {
			shiftLockedRef.current = Math.abs(pointerDiff[0]) > Math.abs(pointerDiff[1]) ? 1 : 0;
		}

		if (shiftLockedRef.current !== null) {
			pointerDiff[shiftLockedRef.current] = 0;
		}

		if (shiftLockedRef.current !== null && !hotkeys.includes('Shift')) {
			shiftLockedRef.current = null;
		}

		const adjTransientPositionDict: IVector3Dict = {};
		for (let i = 0; i < selectedEntityIds.length; i++) {
			const id = selectedEntityIds[i];
			adjTransientPositionDict[id] = [selectedContentPositions[id]![0] + pointerDiff[0], selectedContentPositions[id]![1] + pointerDiff[1], selectedContentPositions[id]![2]];
		}
		dispatch(onSetPositions(adjTransientPositionDict));
		// console.log('pointer move 2')
	};
};

export const useHocPointerUp = (args: {
	pointerStageRef: React.MutableRefObject<'down' | 'move' | 'up' | null>;
	shiftLockedRef?: React.MutableRefObject<number | null>;
	pointerUpHasRunRef?: React.MutableRefObject<boolean | null>;
	initialPosRef: React.MutableRefObject<ITuple3 | null>;
	entity: ISpatialComponentUnion;
	setShowEntityPositionHotspot?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
	const { entity, pointerStageRef, initialPosRef, setShowEntityPositionHotspot, pointerUpHasRunRef, shiftLockedRef } = args;
	const { id } = entity;
	const dispatch = useDispatch();
	const is3dMode = useSelector((state: IDesignerState) => state.userReducer.is3dMode);
	const isScreenRelativeMode = useSelector((state: IDesignerState) => state.userReducer.isScreenRelativeMode);
	const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds || []);
	const selectedTransientPositions = useGetSelectedTransientPositions(id);

	return (e: ThreeEvent<PointerEvent>) => {	
		// console.log('pointer up 1')
		// console.log('pointer up > pointerStageRef.current: ', pointerStageRef.current);
		// Up can only follow move or down
		if (pointerStageRef.current !== 'move' && pointerStageRef.current !== 'down') return;
		dispatch(onSetPositioningIsActive(false));
		if(shiftLockedRef) shiftLockedRef.current = null;
		pointerStageRef.current = 'up';
		// console.log('pointer up > pointerUpHasRunRef?.current: ', pointerUpHasRunRef?.current)
		if (pointerUpHasRunRef && pointerUpHasRunRef.current) {
			(e.target as HTMLElement).releasePointerCapture(e.pointerId);
			e.stopPropagation();
			pointerStageRef.current = null;
			return; // If this is true then it's a double-pointer-up without a pointer move inbetween
		}
		if (e.buttons !== 2 && !!selectedEntityIds && (!is3dMode || isScreenRelativeMode)) {
			const multipleEntityPropsArray_Cn_Doc: IMultipleEntityProps_Cn_Doc[] = [];
			for (let i = 0; i < selectedEntityIds.length; i++) {
				const id = selectedEntityIds[i];
				const entityPos = selectedTransientPositions[id];
				if (entityPos) {
					multipleEntityPropsArray_Cn_Doc.push({
						id,
						position: [entityPos[0], entityPos[1], 0],
					});
				}
			}
			dispatch(onSetMultipleComponentProps_Cn_Doc(multipleEntityPropsArray_Cn_Doc));
			dispatch(onSetPositions(null));
			dispatch(onSetMultipleEntitySelectBoundary(null));
			if (pointerUpHasRunRef) pointerUpHasRunRef.current = true;
		}
		initialPosRef.current = null;
		pointerStageRef.current = null;
		setShowEntityPositionHotspot?.(false);
		// console.log('pointer up 2')
	};
};

export const useGetSelectedContentPositions = () => {
	const contentPositions = useGetContentPositions();
	const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds);
	return useMemo(() => {
		const selectedContentPositions: IVector3Dict = {};
		if (!selectedEntityIds?.length) return selectedContentPositions;
		for (let i = 0; i < selectedEntityIds.length; i++) {
            const contentPosition = contentPositions[selectedEntityIds[i]] as IVector3;
            if (!contentPosition) continue
			selectedContentPositions[selectedEntityIds[i]] = [...contentPosition];
		}
		return selectedContentPositions as ITuple3Dict;
	}, [selectedEntityIds, contentPositions]);
};

export const useGetSelectedTransientPositions = (id: string) => {
	const transientPosition = useSelector((state: IDesignerState) => (state.userReducer.positionById?.[id] as ITuple3) || null);
	const transientPositionDict = useSelector((state: IDesignerState) => state.userReducer.positionById);
	const selectedEntityIds = useSelector((state: IDesignerState) => state.userReducer.selectedEntityIds);

	return useMemo(() => {
		const selectedPositionsPositions: IVector3Dict = {};
		if (!selectedEntityIds?.length) return selectedPositionsPositions;
		for (let i = 0; i < selectedEntityIds.length; i++) {
			if (transientPositionDict[selectedEntityIds[i]]) {
				if (transientPositionDict[selectedEntityIds[i]] !== null) selectedPositionsPositions[selectedEntityIds[i]] = [...(transientPositionDict[selectedEntityIds[i]] as ITuple3)];
			} else {
				selectedPositionsPositions[selectedEntityIds[i]] = null;
			}
		}
		return selectedPositionsPositions as ITuple3Dict;
	}, [selectedEntityIds, transientPosition]);
};

export const useGetContentPositions = () => {
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	return useMemo(() => {
		const cnPositions: IVector3Dict = {};
		Object.keys(componentsById).forEach((id) => {
			const component = componentsById[id];
			if (!!(!isAbstractComponent(component) && component?.type)) {
				cnPositions[id] = [...component.position];
			}
		});
		return cnPositions;
	}, [componentsById]);
};

export const useIsAllHotspotsCompetingWithSelectedEntityPositionHotspotInactive = () => {
	const scaleHotspotIsEnabled = useSelector((state: IDesignerState) => state.userReducer.scaleHotspotIsEnabled);
	const rotationIsActive = useSelector((state: IDesignerState) => state.userReducer.rotationIsActive);
	const backgroundHotspotIsEnabled = useSelector((state: IDesignerState) => state.userReducer.backgroundHotspotIsEnabled);
	return !scaleHotspotIsEnabled && !rotationIsActive && !backgroundHotspotIsEnabled;
};

export const useGetHocCurvedProperties = (entity: ICurveComponentUnion) => {
	const curvature = useSelector((state: IDesignerState) => {
		return state.userReducer?.curvatureById?.[entity.id] || entity.curvature;
	});
	return { curvature };
} 


export const useGetHocSpatialProperties = (entity: ISpatialComponentUnion) => {
	const [sx, sy, sz] = useSelector((state: IDesignerState) => {
		return (state.userReducer.scaleById[entity.id] as ITuple3) || entity.scale;
	}, shallowEqual);
	const [rx, ry, rz] = useSelector((state: IDesignerState) => {
		return (state.userReducer.rotationById[entity.id] as ITuple3) || entity.rotation;
	}, shallowEqual);
	const position = useSelector((state: IDesignerState) => {
		return (state.userReducer.positionById[entity.id] as ITuple3) || entity.position;
	}, shallowEqual);
	const rotation = [maths.toRadians(rx), maths.toRadians(ry), maths.toRadians(rz)] as ITuple3;
	const scale = [sx * 2, sy * 2, sz * 2] as ITuple3;

	return {
		scale,
		position,
		rotation,
	};
};

export const useGetMultipleComponentSpatialProperties = (ids: string[]) => {
	const componentsById = useSelector((state: IDesignerState) => state.contentReducer.contentDoc.componentsById);
	const transientScalesById = useSelector((state: IDesignerState) => state.userReducer.scaleById);
	const transientRotationsById = useSelector((state: IDesignerState) => state.userReducer.rotationById);
	const transientPositionsById = useSelector((state: IDesignerState) => state.userReducer.positionById);

	return getInitialEntityTransformValuesByIds({ids, componentsById, transientScalesById, transientRotationsById, transientPositionsById});
};

export const useGetHocTextProperties = (entity: IButton | IText | IText3d) => {
	const { transientFontRgba, transientFillRgba } = useGetEntityTransientColors(entity.id);

	const fontRgba = transientFontRgba || entity.fontRgba || [0, 0, 0, 1];
	const color = transientFillRgba || (entity as any).color;

	return { fontRgba, color };
};

export const useGetHocBorderProperties = (entity: IButton | IImage | IVideo) => {
	const { transientBorderRgba } = useGetEntityTransientColors(entity.id);

	const borderWidth = entity.borderWidth || 0;
	const borderRadius = entity.borderRadius || 0;
	const borderRgba = transientBorderRgba || entity.borderRgba || [0, 0, 0, 1];

	return { borderWidth, borderRadius, borderRgba };
};
